import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { setBottomSheetViewShowPriceAndChannel } from './../app/Home'
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import ViewDark from './../Public/Image/Home/ViewDark.svg'
import CoinBottomSheet from './../Public/Image/Home/CoinBottomSheet.svg';
import { setMiniLoading } from './../app/Home'
import cryptlib from "cryptlib"
import { db } from './../DataBase/db'
import { useLiveQuery } from "dexie-react-hooks";
import { SetEmail } from './../app/Slice'
// import { SaveViewByCoin } from './../Api/SaveViewByCoin'
import { AllApi } from './../Api/AllApi'
import { GetCookie } from './../Action/GetCookie'
import { setUserFromApi } from './../app/Home'
import { setIsIranian } from './../app/Home'

import { useHistory } from 'react-router-dom';
import { setUserCoin } from './../app/Home'
// import { setBottomSheetViolatingChannel } from './../app/Home'

// import ViolatingChannel from './../BottomSheet/ViolatingChannel'

const ViewShowPriceAndChannel = () => {
    const dispatch = useDispatch()
    const BottomSheetViewShowPriceAndChannel = useSelector((state) => state.Home.BottomSheetViewShowPriceAndChannel)
    const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)
    const location = useLocation();
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const PostLink = useSelector((state) => state.Home.PostLink)
    const history = useHistory();
    const UserFromApi = useSelector((state) => state.Home.UserFromApi)
    const IsIranian = useSelector((state) => state.Home.IsIranian)


    const searchParams = new URLSearchParams(location.search);
    const price = searchParams.get('price');
    const count = parseInt(searchParams.get('count'));
    const ByCoin = searchParams.get('ByCoin');
    const PostViewFilter = searchParams.get('PostViewFilter');
    function onDismiss() {
        setTimeout(() => {
            dispatch(setBottomSheetViewShowPriceAndChannel(false))
        }, 300);
    }
    const Token = GetCookie('Token')

    const GetProfile = async () => {
        const MakeApiClass = new AllApi();
        let ProfileRes = await MakeApiClass.GetProfile(Token)
        let UserPhone
        if (ProfileRes.code == 200) {
            dispatch(setUserFromApi(ProfileRes.data.user))
            if (ProfileRes.data.user) {
                // let IsIraNianForApi=false
                dispatch(SetEmail(ProfileRes.data.user.email))
                UserPhone = ProfileRes.data.user.phonenumber;
                if (UserPhone.length > 0 && UserPhone != undefined && UserPhone != null) {
                    UserPhone.startsWith("+98") ? dispatch(setIsIranian(true)) : dispatch(setIsIranian(false))
                    // UserPhone.startsWith("+98")?IsIraNianForApi=true:IsIraNianForApi=false

                }
            }
        }
        return UserPhone
    }
    useEffect(() => {
        if (IsIranian == undefined) {
            GetProfile()
        }
    }, [])
    const Pay = async () => {
        dispatch(setMiniLoading(true))

        if (ByCoin == 'true') {
            const user1 = {
                data: {
                    // "phonenumber": "989373449122",
                    "email": Email,
                    "_id": _id, // بسته _id
                    "sku": sku,
                    "price": price,
                    "username": UserNameForOrder,
                    "messageIds": [PostViewFilter === "1" ? parseInt(PostLink) : '']
                }

            }
            const MakeApiClass = new AllApi();
            let res = await MakeApiClass.SaveViewByCoin(user1)
            // let res = await SaveViewByCoin(user1)
            // console.log(res)


            if (res.code == 200) {
                dispatch(setUserCoin(res.data.user.coins))
                history.push(`/Home?paymentview=true&username=${UserNameForOrder}&count=${count}`);
            } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))

            }else if(res.code == 302){
                // dispatch(setBottomSheetViolatingChannel(true))
            } else {
                history.push(`/Home?paymentview=false&username=null&count=null`);

            }
        } else if (ByCoin == 'false') {
            if (IsIranian == false) {
                window.location.href = `https://api.membersgram.com/main/v1/webapp/purchaseviewpaypal?data=${data}`
            } else if (IsIranian == true) {
                window.location.href = `https://api.membersgram.com/main/v1/webapp/zarinpal/view/gateway?data=${data}`

            }
            // window.location.href = `https://api.membersgram.com/main/v1/webapp/purchaseviewpaypal?data=${data}`
        }
        dispatch(setBottomSheetViewShowPriceAndChannel(false))

        dispatch(setMiniLoading(false))

    }
    const _id = searchParams.get('_id');
    const sku = searchParams.get('sku');
    const Data = useLiveQuery(
        () => db.Data.toArray()
    );
    useEffect(() => {
        if (Data) {
            dispatch(SetEmail(Data[0].Email))
        }

    }, [Data])
    const Email = useSelector((state) => state.counter.email)
    const iv = '#GV^%^SUR&T*#Y*4'
    const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
    const user = {
        // "phonenumber": "989373449122",
        "email": Email,
        "_id": _id, // بسته _id
        "sku": sku,
        "price": price,
        "username": UserNameForOrder,
        "phonenumber": UserFromApi.phonenumber,
        "messageIds": [PostViewFilter === "1" ? parseInt(PostLink) : '']
    }
    let data = cryptlib.encrypt(JSON.stringify(user), key, iv);
    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetViewShowPriceAndChannel}>
            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Price</div>
                        <div className='ShowPriceAndChannelValue'>{price} {ByCoin == 'true' ? <img src={CoinBottomSheet} /> : IsIranian ? 'T' : <span className='DollarSign'>$</span>} </div>
                    </div>
                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>Count</div>
                        <div className='ShowPriceAndChannelValue'>{count.toLocaleString()} <img src={ViewDark} className='ViewIconInBottomSheet' /></div>
                    </div>
                    {PostViewFilter == '1' ?
                        null
                        :
                        <div className='ShowPriceAndChannelItem'>
                            <div className='ShowPriceAndChannelTitle'>Number of post</div>
                            <div className='ShowPriceAndChannelValue'>{PostViewFilter} post</div>
                        </div>}

                    <div className='ShowPriceAndChannelItem'>
                        <div className='ShowPriceAndChannelTitle'>{PostViewFilter == '1' ? 'Post link' : 'Username'}</div>
                        <div className='ShowPriceAndChannelValue'>{UserNameForOrder}{PostViewFilter === '1' ? '/' + PostLink : null}</div>
                    </div>
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    {/* <button className='BottomSheetCancelButton' onClick={() => dispatch(setBottomSheetCheckUsername(false))}>Cancel</button> */}
                    {!MiniLoading ?
                        <button className='BottomSheetShowPriceAndChannelButton' onClick={() => Pay(data)}>Pay</button>
                        : <button disabled className='BottomSheetShowPriceAndChannelButtonDisable' onClick={() => Pay(data)}>Pay</button>}
                </div>
            </div>
        </BottomSheet>
    )
}

export default ViewShowPriceAndChannel