import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { setInsufficientBalance } from './../app/Home'
import Animation from './../Public/Image/Join/2.json'
import Lottie from "lottie-react";

import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
const InsufficientBalance = () => {
    const dispatch = useDispatch()

    const InsufficientBalance = useSelector((state) => state.Home.InsufficientBalance)

    function onDismiss() {
        setTimeout(() => {

        dispatch(setInsufficientBalance(false))
    }, 300);
    }
    return (
        <BottomSheet onDismiss={onDismiss} open={InsufficientBalance}>
              <div className='RegisterBeforeBottomSheet'>

<div className='ShowPriceAndChannelBottomSheetBody'>
    <div className='successfulPaymentItem' style={{ height: "auto" }}>

        <div className='UnsuccessfulPaymentValue Insufficient'>
            Not enough coins
        </div>
        <div className='UnsuccessfulPaymentValue Insufficient'>
            <Lottie animationData={Animation} loop={true} style={{ "width": "125px", "height": "133px" }} />
        </div>
        <div className='UnsuccessfulPaymentValue Insufficient'>
            You don’t have enough coins to buy this bundle.
        </div>
        {/* <div className='SuccessfulPaymentText'>
        You don’t have enough coins to buy this bundle.
    </div> */}
    </div>

</div>
<div className='RegisterBeforeBottomSheetFooter'>

    <button className='BottomSheetShowPriceAndChannelButton' style={{color:"#1976D2",backgroundColor:"#fff",}} onClick={() => onDismiss()}>Got it</button> 


    <Link to="/Coin?Status=Join" className='HeaderCoin BottomSheetShowPriceAndChannelButton'>Get free Coin </Link >
     {/* <button className='BottomSheetShowPriceAndChannelButton'  onClick={() => onDismiss()}>Get free Coin</button> */}
</div>
</div>
        </BottomSheet>
    )
}

export default InsufficientBalance