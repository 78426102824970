import React from 'react'
import Coin from './../Public/Image/Join/Coin.svg'
import CoinBig from './../Public/Image/Home/Coin.svg'

import { useEffect } from 'react'
import './CoinHeader.css'
import { useSelector, useDispatch } from 'react-redux'
// import { GetProfile } from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from '../Action/GetCookie'
import { setUserCoin } from './../app/Home'
import { SetEmail } from './../app/Slice'
import { db } from './../DataBase/db'
import { useHistory } from 'react-router-dom';
import { TelegramDB } from './../DataBase/TelegramDB'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Link } from 'react-router-dom';
import { SetActiveTab } from './../app/JoinState'
import { setLoading } from './../app/Home'
import xpplus from './../Public/Image/Join/xpplus.svg'
import { useState } from 'react'
import HeadImg from './../../src/Public/Image/Game/Group 26086606.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { GameDB } from '../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';

const CoinHeader = () => {
    const dispatch = useDispatch()
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)
    const history = useHistory();

    const UserCoin = useSelector((state) => state.Home.UserCoin)
    const Token = GetCookie('Token');
    const [PointDialogState, setPointDialogState] = useState('')
    const [xpCount, setxpCount] = useState('')
    const [xpLevel, setxpLevel] = useState('')
    const [xpLimit, setxpLimit] = useState('')
    const GetProfileFunction = async () => {
        const MakeApiClass = new AllApi();

        let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
        // console.log(TelegramAccountUpdatelimit)
        if (TelegramAccountUpdatelimit.length > 0) {
            let arr = []
            TelegramAccountUpdatelimit.map(item => {
                arr.push(item.BotSession)
            })
            console.log(arr)
            let restransfer = await MakeApiClass.accountTransfer(arr)
            //  console.log(restransfer)
            arr = []
        }else{
            let arr = []
          
            console.log(arr)
            let restransfer = await MakeApiClass.accountTransfer(arr)
          
        }






        let AppSetting = await GameDB.AppSetting.toArray()
        //  console.log(AppSetting)
        setPointDialogState(AppSetting[0].AppSetting.PointDialog)

        let res = await MakeApiClass.GetProfile(Token)
        // let res = await GetProfile(Token)
        // console.log(res)
        setxpCount(res.data.user.xpCount)
        setxpLevel(res.data.user.xpLevel)
        setxpLimit(res.data.user.xpLimit)
        if (res.code == 200) {
            dispatch(setUserCoin(res.data.user.coins))
            let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })
            dispatch(SetEmail(res.data.user.email))

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }else if (res.code == 999) {
            console.log('error network')
            handleClick({ vertical: 'bottom', horizontal: 'center' })
      
          }

    }
 const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    
    useEffect(() => {
        GetProfileFunction()
    }, [UserCoin])
    const urlParams = new URLSearchParams(window.location.search);


    // const Tab = useSelector((state) => state.JoinState.Tab)
    // const ActiveTab = useSelector((state) => state.JoinState.ActiveTab)
    const SetTabsStatusJoin = (index) => {
        dispatch(setLoading(true))
        localStorage.setItem("CoinFilter", index)
        dispatch(SetActiveTab(index))
        let name
        if (index == 0) {
            name = "Join"
        } else if (index == 1) {
            name = 'Buy'
        } else if (index == 2) {
            name = 'Sale'
        } else if (index == 3) {
            name = "Transfer"
        } else if (index == 4) {
            name = "Gift"
        }

        urlParams.set('Status', index);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + urlParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
        setTimeout(() => {
            dispatch(setLoading(false))
        }, 100000);

    }




    const OpenBottomSheetShowXpDetails = () => {
        setBottomSheetState(true)
    }
    const [BottomSheetState, setBottomSheetState] = useState(false)
    function onDismiss() {
        setTimeout(() => {
            setBottomSheetState(false)
        }, 300);

    }

    function abbreviate_number(num, fixed) {
        //   console.log(num)
        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return e;
    }
    return (

        <div className='CoinHeader'>
            <div className='HeaderLogo' onClick={() => OpenBottomSheetShowXpDetails()}>
                {/* Coin */}
                <div className='HeaderLogoPtLevel' >

                    <div className='HeaderProgressBarImgContainer'>
                        <div className='HeaderProgressBarAbsoluteNumber'>
                            {xpLevel}

                        </div>
                        <img src={HeadImg} className='HeaderProgressBarImg' />
                    </div>

                </div>
                <div className='HeaderLogoPt'>
                    <div>
                    <span style={{'fontWeight':'700'}}>
                        {abbreviate_number(xpLimit - xpCount)} </span>
                        Pts


                    </div>
                    <div>
                        To next lvl
                    </div>
                </div>
            </div>
            {/* <div  className='HeaderCoin' onClick={() => SetTabsStatusJoin('Join')}> */}
            <div className='HeaderCoin' >
                {/* <AddCircleRoundedIcon className='AddCoinIcon' /> */}

                <div>
                    {UserCoin.toLocaleString()}
                </div>
                <div className='HeaderCoinImgContainer'>
                    {
                        CountJoining > 0 ?
                            <>
                                <div className='CoinHeaderImageLoader'></div>
                                <img src={Coin} className='HeaderCoinImg1' />
                            </>

                            :
                            <img src={Coin} className='HeaderCoinImg1' />

                    }

                </div>

            </div>

            <BottomSheet onDismiss={onDismiss} open={BottomSheetState}>
                <div className='RegisterBeforeBottomSheet'>
                    <div className='RegisterBeforeBottomSheetHeader'>

                        How can we get Points (<img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px" }} />) ?
                    </div>
                    <ul className='BottomSheetUlXpUl'>
                        {
                            PointDialogState ?
                                PointDialogState.map((item, i) =>
                                    <li key={i} style={{ "marginTop": "8px" }}>{item}</li>
                                )
                                : null
                        }

                    </ul>

                    <div className='RegisterBeforeBottomSheetFooter'>
                        <button className='BottomSheetCancelButton' style={{ "width": "100%" }} onClick={() => setBottomSheetState(false)}>Got it</button>
                    </div>
                </div>
            </BottomSheet>
              <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={opens}
                    onClose={handleClose}
                    message="Something went wrong"
                    key={vertical + horizontal}
                    autoHideDuration={3000}
                  />
        </div>
    )
}

export default CoinHeader