import React from 'react'
import ProgressBar from './../ProgressBar'
import BronzeImg from './../../Public/Image/Game/bronze.svg'
import SilverImg from './../../Public/Image/Game/Silver.svg'
import GoldImg from './../../Public/Image/Game/Gold.svg'
import PlatinumImg from './../../Public/Image/Game/Platinum.svg'
import DimondImg from './../../Public/Image/Game/Dimond.svg'
import EpicImg from './../../Public/Image/Game/Epic.svg'
import LegendryImg from './../../Public/Image/Game/Legendry.svg'
import MasterImg from './../../Public/Image/Game/Master.svg'
import GrandMasterImg from './../../Public/Image/Game/GrandMaster.svg'
import LordImg from './../../Public/Image/Game/Lord.svg'
import CreatorImg from './../../Public/Image/Game/Creator.svg'
import Coin from './../../Public/Image/Game/Coin.svg'
import Star from './../../Public/Image/Game/Star.svg'
import Reward from './../../Public/Image/Game/Reward.svg'
import Loading from './../../Loading'
import "./../../Public/fonts/font.css"
import { useState, useEffect } from 'react'
import './MyLeagueBronze.css'
import { Link } from 'react-router-dom';
import { AllApi } from '../../Api/AllApi'
import { GetCookie } from './../../Action/GetCookie'
import { useSelector, useDispatch } from 'react-redux'
import { GameDB } from './../../DataBase/GameDB'
import { CheckDataBaseGame } from '../../GameAction/CheckDataBaseGame'

const MyLeagueComponent = ({ LastUpdate }) => {
    const AppSettingData = useSelector((state) => state.Game.AppSettingData)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const RewardLeague = useSelector((state) => state.Game.RewardLeague)
    const LeagueLimitJoinProgress = useSelector((state) => state.Game.LeagueLimitJoinProgress)
    const NameAccount = useSelector((state) => state.Game.NameAccount)
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    const dispatch = useDispatch()

    const [League, setLeague] = useState('')
    const [Rank, setRank] = useState('')
    const [Cup, setCup] = useState('')
    const [ForStyle, setForStyle] = useState('')
    const [IsCreator1, setIsCreator1] = useState(false)
    const [LoadState, setLoadState] = useState(true)
    const [IsInTop100, setIsInTop100] = useState(null)
    const [RemainJoin, setRemainJoin] = useState('')
    // const [LastUpdate, setLastUpdate] = useState('')
    const [UpdatedTopJoiner, setUpdatedTopJoiner] = useState('')
    //     useEffect(() => {
    //         // StartGame()
    //         // StartTimer()
    //         // console.log("start")
    //       }, [])
    //       const StartGame = async () => {
    //         // GameDB.Profile.toArray(async function (ProfileItem) {console.log(ProfileItem[0].Profile.nameAccount)})
    //           const CheckDataBaseGameClass = new CheckDataBaseGame();
    //           await CheckDataBaseGameClass.CheckDataBase(dispatch)

    //       }



    useEffect(() => {
        // StartGame()

        setLoadState(true)
        // console.log(MyLeagueRank)
        if (MyLeagueRank.indexOf('Bronze') >= 0) {
            setLeague('Bronze')
            setForStyle(Style.Bronze)
            var rank = MyLeagueRank.replace('Bronze', '');
            setRank(rank)
            setIsCreator1(false)
        } else if (MyLeagueRank.indexOf('Silver') >= 0) {
            setLeague('Silver')
            setForStyle(Style.Silver)
            var rank = MyLeagueRank.replace('Silver', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Gold') >= 0) {
            setLeague('Gold')
            setForStyle(Style.Gold)
            var rank = MyLeagueRank.replace('Gold', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Platinum') >= 0) {
            setLeague('Platinum')
            setForStyle(Style.Platinum)
            var rank = MyLeagueRank.replace('Platinum', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Diamond') >= 0) {
            setLeague('Dimond')
            setForStyle(Style.Dimond)
            var rank = MyLeagueRank.replace('Diamond', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Epic') >= 0) {
            setLeague('Epic')
            setForStyle(Style.Epic)
            var rank = MyLeagueRank.replace('Epic', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Legendary') >= 0) {
            setLeague('Legendary')
            setForStyle(Style.Legendary)
            var rank = MyLeagueRank.replace('Legendary', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Master') >= 0) {
            setLeague('Master')
            setForStyle(Style.Master)
            var rank = MyLeagueRank.replace('Master', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Grandmaster') >= 0) {
            setLeague('Grandmaster')
            setForStyle(Style.Grandmaster)
            var rank = MyLeagueRank.replace('Grandmaster', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Lord') >= 0) {
            setLeague('Lord')
            setForStyle(Style.Lord)
            var rank = MyLeagueRank.replace('Lord', '');
            setRank(rank)
            setIsCreator1(false)
        }
        else if (MyLeagueRank.indexOf('Creator') >= 0) {
            setLeague('Creator')
            setForStyle(Style.Creator)
            var rank = MyLeagueRank.replace('Creator', '');
            setRank(rank)
            // console.log(rank)
            if (rank == ' I') {
                setIsCreator1(true)
            } else {
                setIsCreator1(false)

            }

        }
        setTimeout(() => {
            setLoadState(false)
        }, 2000);

        // console.log(MyLeagueRank)
    }, [MyLeagueRank])




    const Style = {
        Bronze: {
            img: BronzeImg,
            Rank: '#682920',
            Progress: '#BF8970',
            ListBorder: '#BF8970',
            ListTitleBg: '#BF8970',
            ListTitleText: '#321203',
            ListTitleYou: '#4B2714',
            ListTitleYouBg: '#FFEDE6',
            ListBodyBg: '#FFEDE6',
            ListBodyText: '#321203'
        },
        Silver: {
            img: SilverImg,
            Rank: '#5E7081',
            Progress: '#8E9FAF',
            ListBorder: '#8E9FAF',
            ListTitleBg: '#8E9FAF',
            ListTitleText: '#22323F',
            ListTitleYou: '#22323F',
            ListTitleYouBg: '#F3F3F3',
            ListBodyBg: '#F3F3F3',
            ListBodyText: '#384956'
        },
        Gold: {
            img: GoldImg,
            Rank: '#8F4F2C',
            Progress: '#FFD100',
            ListBorder: '#FFD100',
            ListTitleBg: '#FFD100',
            ListTitleText: '#3C2F00',
            ListTitleYou: '#3C2F00',
            ListTitleYouBg: '#FFF0C9',
            ListBodyBg: '#FFF0C9',
            ListBodyText: '#564500'
        },
        Platinum: {
            img: PlatinumImg,
            Rank: '#373D37',
            Progress: '#ACB2AC',
            ListBorder: '#ACB2AC',
            ListTitleBg: '#ACB2AC',
            ListTitleText: '#2C322E',
            ListTitleYou: '#2C322E',
            ListTitleYouBg: '#DEE4DD',
            ListBodyBg: '#DEE4DD',
            ListBodyText: '#424844'
        },
        Dimond: {
            img: DimondImg,
            Rank: '#24B6CA',
            Progress: '#B9F2FF',
            ListBorder: '#B9F2FF',
            ListTitleBg: '#B9F2FF',
            ListTitleText: '#00363E',
            ListTitleYou: '#2C322E',
            ListTitleYouBg: '#EDFCFF',
            ListBodyBg: '#EDFCFF',
            ListBodyText: '#0B4E59'
        },
        Epic: {
            img: EpicImg,
            Rank: '#42017E',
            Progress: '#8C49CA',
            ListBorder: '#8C49CA',
            ListTitleBg: '#8C49CA',
            ListTitleText: '#FAECFF',
            ListTitleYou: '#4A007F',
            ListTitleYouBg: '#FAECFF',
            ListBodyBg: '#FAECFF',
            ListBodyText: '#4A007F'
        },
        Legendary: {
            img: LegendryImg,
            Rank: '#851212',
            Progress: '#C00021',
            ListBorder: '#C00021',
            ListTitleBg: '#C00021',
            ListTitleText: '#FFEDEB',
            ListTitleYou: '#68000D',
            ListTitleYouBg: '#FFEDEB',
            ListBodyBg: '#FFEDEB',
            ListBodyText: '#68000D'
        },
        Master: {
            img: MasterImg,
            Rank: '#2424CD',
            Progress: '#5656FF',
            ListBorder: '#5656FF',
            ListTitleBg: '#5656FF',
            ListTitleText: '#FFFBFF',
            ListTitleYou: '#1300A9',
            ListTitleYouBg: '#F2EFFF',
            ListBodyBg: '#F2EFFF',
            ListBodyText: '#00006E'
        },
        Grandmaster: {
            img: GrandMasterImg,
            Rank: '#1C1C86',
            Progress: '#0202D3',
            ListBorder: '#0202D3',
            ListTitleBg: '#0202D3',
            ListTitleText: '#E0E0FF',
            ListTitleYou: '#0202A9',
            ListTitleYouBg: '#E0E0FF',
            ListBodyBg: '#E0E0FF',
            ListBodyText: '#0202A9'
        },
        Lord: {
            img: LordImg,
            Rank: '#112E1F',
            Progress: '#006C44',
            ListBorder: '#006C44',
            ListTitleBg: '#006C44',
            ListTitleText: '#C0FFD7',
            ListTitleYou: '#005232',
            ListTitleYouBg: '#C0FFD7',
            ListBodyBg: '#C0FFD7',
            ListBodyText: '#005232'
        },
        Creator: {
            img: CreatorImg,
            Rank: '#4E4E4E',
            Progress: '#212121',
            ListBorder: '#212121',
            ListTitleBg: '#212121',
            ListTitleText: '#FFFFFF',
            ListTitleYou: '#212121',
            ListTitleYouBg: '#FFFFFF',
            ListBodyBg: '#E0E0E0',
            ListBodyText: '#212121'
        },
    }




    React.useEffect(() => {

        if (UserLeagueData.topUsers) {
            // OfflineRequestLeagueList()
            // console.log(UserLeagueData)
            let UserEmail = UserLeagueData.userInformation.email
            let Me = UserLeagueData.userInformation
            let TopUser = UserLeagueData.topUsers
            // console.log(TopUser)
            // console.log(Me)

            // TopUser.find(UserEmail);

            const UpdateTJoiner = TopUser.map((item, i) => {
                if (item.joinCount == Me.joinCount) {
                    // console.log(item)
                    return { ...item, email: Me.email, joinCount: Me.joinCount, nameAccount: Me.nameAccount, picture: Me.picture, _id: Me.joinCount };

                } else {
                    return item
                }
            })
            setUpdatedTopJoiner(UpdateTJoiner);

            UpdateTJoiner.map((item, i) => {
                // console.log(item)  
                // console.log(UserEmail)  
                if (item.email == UserEmail) {

                    setIsInTop100({ name: item.email, Rank: i + 1 })
                    // console.log(i + 1) 
                }
            })

            //   console.log("JoinCountRedux".JoinCountRedux)
            //   console.log("LeagueLimitJoinProgress",LeagueLimitJoinProgress)

            let Count = JoinCountRedux

            let ProgressFinal = LeagueLimitJoinProgress - Count
            if (ProgressFinal > 0) {
                setRemainJoin(ProgressFinal)
            } else {
                setRemainJoin(0)

            }
        }



    }, [UserLeagueData, LeagueLimitJoinProgress, JoinCountRedux])
    function abbreviate_number(num, fixed) {

        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
        return e;
    }
    // console.log(IsInTop100)
    // console.log(UserLeagueData)

    function abbreviate_number(num, fixed) {
        //   console.log(num)
        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return e;
    }

    return (
        !LoadState
            ?
            <div className='MyLeagueComponent'>
                {!IsCreator1 ?
                    <div className='MyLeague'>
                        <div className='MyLeagueRow1'>
                            <div className='MyLeagueInner'>
                                <img src={ForStyle.img} className='GameCupImg' />
                                <div className='GameLeagueName' >{MyLeagueRank} </div>
                            </div>
                            <div className='MyLeagueInner1'>
                                {
                                    RemainJoin == 0 ?
                                        <div className='GameCoinRemainJoin'> Completed </div>
                                        : <div
                                            className='GameNeddForJoin'
                                        >
                                            <b style={{ color: '#212121' }}>
                                                {RemainJoin.toLocaleString()}
                                                {RemainJoin > 1 ? " Joins" : " Join"}

                                            </b> to next league
                                        </div>
                                }
                            </div>
                        </div>
                        <div className='MyLeagueRow2'>
                            <div className='GameLeagueRate' style={{ backgroundColor: ForStyle.Rank }}>{Rank}</div>
                            <div className="ProgressBarContainer" >
                                <ProgressBar Color={ForStyle.Progress} />
                            </div>
                        </div>
                        <div className='MyLeagueRow3'>
                            <div className='RewardNumber'>
                                <span className='RewardNumberTitle'>Reward: </span>
                                &nbsp;
                                <span className='RewardNumberCoinNumber'>{RewardLeague.coin ? abbreviate_number(RewardLeague.coin) : 0}</span>
                                &nbsp;
                                <img src={Coin} className='CoinReward' />


                                +


                                <span className='RewardNumberCoinNumber'>{RewardLeague.xp ? abbreviate_number(RewardLeague.xp) : 0}</span>
                                &nbsp;
                                <img src={Star} className='StarReward' />
                            </div>
                            <Link to="/AllReward" className='AllRewardNumber'>
                                <img src={Reward} className='AllRewardNumberImg' />   All Reward
                            </Link>
                        </div>
                        {

                            LastUpdate ?
                                <span className='LastUpdate'>
                                    Last Update :  {LastUpdate} min ago
                                </span>
                                : <span className='LastUpdate'>
                                    &nbsp;
                                </span>
                        }

                        <div className='MyLeagueRow4' style={{ borderColor: ForStyle.ListBorder }}>
                            <div className='MyLeagueRow4Title' style={{ backgroundColor: ForStyle.ListTitleBg }}>
                                <div className='MyLeagueRow4TitleRank'>

                                    <div className='MyLeagueRow4TitleRankNumber' style={{ color: ForStyle.ListTitleText }}>
                                        {
                                            { IsInTop100 } ?
                                                <>
                                                    <div style={{ fontSize: "12px" }}>Rank </div>
                                                    <span>{IsInTop100 != null ? IsInTop100.Rank : '+ 100'}</span>
                                                </> :
                                                <div>+ 100</div>

                                        }



                                    </div>
                                    {
                                        UserLeagueData.userInformation.picture == null ?
                                            <span className='ProfilePhotoText AccountText' style={{ "width": "32px", "height": "32px", "marginLeft": "12px", "marginTop": "16px", "fontSize": "14px" }}>

                                                {UserLeagueData.userInformation.email.charAt(0).toUpperCase()}
                                            </span>
                                            :
                                            <img src={UserLeagueData.userInformation.picture} className='ImageProfileUser' style={{ "marginLeft": "12px" }} />
                                    }

                                    <div className='MyLeagueRow4TitleRankUser' style={{ color: ForStyle.ListTitleText }}>

                                        {UserLeagueData ?

                                            UserLeagueData.userInformation.nameAccount.length > 14 ?
                                                // UserLeagueData.userInformation.nameAccount.substring(0, 10) + '...'
                                                <marquee className="marquee" behavior="scroll" direction="left" scrollamount="4">
                                                    {UserLeagueData.userInformation.nameAccount}
                                                </marquee>
                                                : UserLeagueData.userInformation.nameAccount : null

                                        }
                                    </div>
                                    <div className='MyLeagueRow4TitleRankIsUser' style={{ color: ForStyle.ListTitleYou, backgroundColor: ForStyle.ListTitleYouBg }}>
                                        You
                                    </div>
                                </div>
                                <div className='MyLeagueRow4TitleJoinRank' style={{ color: ForStyle.ListTitleText }}>
                                    {
                                        // JoinCountRedux.toLocaleString()
                                        JoinCountRedux > 0 ? abbreviate_number(JoinCountRedux) : 0
                                    }
                                    {JoinCountRedux > 1 ? " Joins" : " Join"}


                                </div>
                            </div>
                            {/* inja */}
                            <div className='MyLeagueRow4Items' style={{ borderColor: ForStyle.ListBorder, backgroundColor: ForStyle.ListBodyBg, color: ForStyle.ListBodyText }}>
                                {
                                    UpdatedTopJoiner ?
                                        UpdatedTopJoiner.map((item, i) =>
                                            <div className='MyLeagueRow4Item' key={i}>
                                                <div className='MyLeagueRow4ItemInner' style={{ color: ForStyle.ListBodyText }}>
                                                    <span className='MyLeagueRow4ItemInnerNumber'>{i + 1}</span>
                                                    {item.picture ?
                                                        <img src={item.picture} className='ImageProfileUser' /> :
                                                        <span className='ProfilePhotoText AccountText' style={{ "width": "32px", "height": "32px", "marginRight": "16px", "marginBottom": "0" }}>
                                                            {item.email.charAt(0).toUpperCase()}
                                                        </span>}
                                                    {/* {item.nameAccount} */}
                                                    {
                                                        item.nameAccount.length > 14 ?
                                                            // item.nameAccount.substring(0, 10) + '...' 
                                                            <marquee behavior="scroll" direction="left" scrollamount="3">{item.nameAccount}</marquee>

                                                            :
                                                            item.nameAccount
                                                    }
                                                    {
                                                        UserLeagueData.userInformation.nameAccount == item.nameAccount && UserLeagueData.userInformation.email == item.email ? <span className='MyLeagueRow4TitleRankIsUser'
                                                            style={{ backgroundColor: ForStyle.ListTitleBg, color: ForStyle.ListTitleText }}>You</span> : null
                                                    }

                                                </div>
                                                <div className='MyLeagueRow4ItemInner' style={{ color: ForStyle.ListBodyText }}>
                                                    {/* {item.joinCount.toLocaleString()} */}
                                                    {abbreviate_number(item.joinCount)}
                                                    {item.joinCount > 1 ? " Joins" : " Join"}


                                                </div>

                                            </div>
                                        )

                                        : null
                                }



                            </div>
                        </div>
                    </div>
                    :
                    <div className='MyLeagueCreator'>
                        <div className='MyLeagueCreatorRow1'>

                            <img src={ForStyle.img} className='MyLeagueCreatorGameCupImg' />



                        </div>
                        <div className='MyLeagueCreatorRow2'>
                            CREATOR
                        </div>
                        <div className='MyLeagueCreatorRow2'>

                            <Link to="/AllReward" className='AllRewardNumber'>
                                <img src={Reward} className='AllRewardNumberImg' />   All Reward
                            </Link>
                        </div>
                        <div className='MyLeagueRow4' style={{ borderColor: ForStyle.ListBorder, marginTop: '16px' }}>

                            <div className='MyLeagueRow4Items' style={{ backgroundColor: ForStyle.ListBodyBg, color: ForStyle.ListBodyText, borderRadius: '8px' }}>
                                {
                                    UpdatedTopJoiner ?
                                        UpdatedTopJoiner.map((item, i) =>
                                            <div className='MyLeagueRow4Item' key={i}>
                                                <div className='MyLeagueRow4ItemInner' style={{ color: ForStyle.ListBodyText }}>
                                                    <span className='MyLeagueRow4ItemInnerNumber'>{i + 1}</span>
                                                    {item.nameAccount}
                                                    {
                                                        UserLeagueData.userInformation.nameAccount == item.nameAccount ? <span className='MyLeagueRow4TitleRankIsUser'
                                                            style={{ backgroundColor: ForStyle.ListTitleBg, color: ForStyle.ListTitleText }}>You</span> : null
                                                    }

                                                </div>
                                                <div className='MyLeagueRow4ItemInner' style={{ color: ForStyle.ListBodyText }}>
                                                    {item.joinCount.toLocaleString()} Join

                                                </div>

                                            </div>

                                        )

                                        : null
                                }



                            </div>

                        </div>
                    </div>
                }
            </div>
            : <Loading />
    )


}

export default MyLeagueComponent