import React from 'react'
import './ViewPackItem.css'
import ViewIcon from './../Public/Image/Home/ViewIcon.svg'
import Coin from './../Public/Image/Home/Coin.svg'
import { GetView } from './../Api/GetView'
import { GetCookie } from '../Action/GetCookie'
import { useEffect } from 'react'
import { setViewPackageData } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { AllApi } from './../Api/AllApi'
import { SetEmail } from './../app/Slice'
// import { setInsufficientBalance } from './../app/Home'
// import InsufficientBalance from './../BottomSheet/InsufficientBalance'
import Animation from './../Public/Image/Join/2.json'
import Lottie from "lottie-react";
import { BottomSheet } from 'react-spring-bottom-sheet'
import { Link } from 'react-router-dom';
import xpplus from './../Public/Image/Join/xpplus.svg'
import { useState } from 'react'
const ViewPackItemCoin = () => {
    const Token = GetCookie('Token')
    const ViewPackageData = useSelector((state) => state.Home.ViewPackageData)
    const PostViewFilter = useSelector((state) => state.Home.PostViewFilter)
    const dispatch = useDispatch()
    const history = useHistory();


    // const GetViewFunc = async () => {
    //     // let res = await GetView(Token)
    //     const MakeApiClass = new AllApi();
    //     let res = await MakeApiClass.GetView()
    //     // console.log(res)
    //     if (res.code == 200) {
    //         dispatch(setViewPackageData(res.data.data))

    //     }else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
    //         let name = "Token"
    //         let name1 = "active"
    //         document.cookie = name + '=; Max-Age=-99999999;';
    //         document.cookie = name1 + '=; Max-Age=-99999999;';
    //         history.push("/");

    //     }

    // }
    useEffect(() => {
        // console.log('set view coin')
        // if (ViewPackageData.length === 0) {
        // GetViewFunc()
        // }

    }, [])



    const CheckViewPackageItem = async (price, count, sku, _id) => {
        let coins = await GetProfile()
        // console.log(coins)
        // console.log(price)
        if (coins >= price) {
            history.push(`/GetUsernameView?price=${price}&count=${count}&ByCoin=true&PostViewFilter=${PostViewFilter}&sku=${sku}&_id=${_id}`);
        } else {
            // dispatch(setInsufficientBalance(true))
            setBottomSheet1(true)

        }

    }
    const GetProfile = async () => {
        const MakeApiClass = new AllApi();
        let ProfileRes = await MakeApiClass.GetProfile(Token)
        let coins
        if (ProfileRes.code == 200) {
            // console.log(ProfileRes.data.user.coins)
            // setUserCoin(ProfileRes.data.user.coins)
            coins = ProfileRes.data.user.coins
            // dispatch(setUserFromApi(ProfileRes.data.user))
            if (ProfileRes.data.user) {
                // let IsIraNianForApi=false
                dispatch(SetEmail(ProfileRes.data.user.email))

            }
        }
        return coins
    }
    const [BottomSheet1, setBottomSheet1] = useState(false)
    function onDismiss() {
        setTimeout(() => {

            setBottomSheet1(false)
        }, 300);
    }
    return (
        <>
            <BottomSheet onDismiss={onDismiss} open={BottomSheet1}>
                <div className='RegisterBeforeBottomSheet'>

                    <div className='ShowPriceAndChannelBottomSheetBody'>
                        <div className='successfulPaymentItem' style={{ height: "auto" }}>

                            <div className='UnsuccessfulPaymentValue Insufficient'>
                                Not enough coins
                            </div>
                            <div className='UnsuccessfulPaymentValue Insufficient'>
                                <Lottie animationData={Animation} loop={true} style={{ "width": "125px", "height": "133px" }} />
                            </div>
                            <div className='UnsuccessfulPaymentValue Insufficient'>
                                You don’t have enough coins to buy this bundle.
                            </div>
                            {/* <div className='SuccessfulPaymentText'>
                            You don’t have enough coins to buy this bundle.
                        </div> */}
                        </div>

                    </div>
                    <div className='RegisterBeforeBottomSheetFooter'>

                        <button className='BottomSheetShowPriceAndChannelButton' style={{color:"#1976D2",backgroundColor:"#fff",}} onClick={() => onDismiss()}>Got it</button> 


                        <Link to="/Coin?Status=Join" className='HeaderCoin BottomSheetShowPriceAndChannelButton'>Get free Coin </Link >
                         {/* <button className='BottomSheetShowPriceAndChannelButton'  onClick={() => onDismiss()}>Get free Coin</button> */}
                    </div>
                </div>
            </BottomSheet>
            {
                ViewPackageData.length > 0 ?
                    ViewPackageData.map((ViewItem, i) =>
                        ViewItem.count_post == PostViewFilter ?
                            ViewItem.coin.map((item, j) =>
                                <div className='ViewPackageItem' key={j} onClick={() => CheckViewPackageItem(item.coin, item.view_count, item.sku, item._id)}>
                                    <div className='ViewItem'>
                                        <div>
                                            <img src={ViewIcon} />
                                        </div>
                                        <div className='ViewItemDigit'>
                                            <div className='ViewItemDigitNewPrice'>

                                                {item.view_count.toLocaleString()}
                                                {item.xpCount ?<span className='XpCountInlist'>+ {item.xpCount} <img src={xpplus} className='XpCountInlistIcon' /> </span>: null}  

                                            </div>
                                            {/* <div className='ViewItemDigitOldPrice'>
                                            {item.view_count_real}
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className='ViewItem'>

                                        {/* <div className='ViewItemOffDigit'>
                                            25% OFF
                                        </div> */}
                                        <div >
                                            <div className='ViewItemOrder'>
                                                <button className='ViewItemOrderButton'> <img className='CoinIconButton' src={Coin} />{item.coin.toLocaleString()}</button>
                                            </div>
                                            {/* <div className='ViewItemOrderOldPrice'>
                                                10.900 $
                                            </div> */}
                                        </div>

                                    </div>
                                </div>
                            )

                            : null
                    )
                    : null
            }
            {/* <InsufficientBalance /> */}

        </>


    )
}

export default ViewPackItemCoin