import React from 'react'
import './LogOut.css'
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
// import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useHistory } from "react-router-dom";
import { db } from './../DataBase/db'
import { TelegramDB } from './../DataBase/TelegramDB'
import { GameDB } from './../DataBase/GameDB'
import { SaleNumber } from './../DataBase/SaleNumber'

import { GetCookie } from './../Action/GetCookie'
// import { CheckDeleteAccountApi } from './CheckDeleteAccountApi';
import { useEffect, useState } from 'react';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import { BottomSheet } from 'react-spring-bottom-sheet'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import Loading from './../Loading'
import { AllApi } from './../Api/AllApi'

// import Dexie from 'dexie';
const LogOut = () => {
    const history = useHistory();
    let Token = GetCookie('Token');
    const OpenBottomSheet = () => {
        setOpen(true)
    }
    const [open, setOpen] = useState(false)
    function onDismiss() {
        setTimeout(() => {
            setOpen(false)
        }, 300);
    }
    function deleteAllCookies(name) {
        // const cookies = document.cookie.split(";");
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        // for (let i = 0; i < cookies.length; i++) {
        //     const cookie = cookies[i];
        //     const eqPos = cookie.indexOf("=");
        //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        // }
    }
    useEffect(() => {
        CheckDeleteAccount()
    }, [])
    const [CanDelete, setCanDelete] = useState(false)
    const [NegativeCoin, setNegativeCoin] = useState(false)
    const [LoadingState, setLoadingState] = useState(false)
    const CheckDeleteAccount = async () => {
        setLoadingState(true)
        // let res = await CheckDeleteAccountApi(Token)
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.CheckDeleteAccountApi(Token)
        // console.log(res)
        if (res.code == 200) {
            setCanDelete(true)

        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        } else if (res.code == 302) {
            // console.log('coin < 0')
            setNegativeCoin(true)
            setCanDelete(false)

        } else {
            setCanDelete(false)
        }
        setLoadingState(false)

    }
    const LogOutUser = () => {
        deleteAllCookies('active')
        deleteAllCookies('Token')
        // active
        // Token
        let name = "Token"
        let name1 = "active"
        document.cookie = name + '=; Max-Age=-99999999;';
        document.cookie = name1 + '=; Max-Age=-99999999;';
       
        // db.delete();
         db.Data.clear();
        //  db.Data.delete();
        // console.log("LogOutUser")
        // db.Data.clear();
        TelegramDB.Data.clear();
        GameDB.AppSetting.clear();
        GameDB.LeagueList.clear();
        GameDB.Profile.clear();
        //    l GameDB.Time.clear();
        SaleNumber.Data.clear();
        // localStorage.clear()
        GameDB.TopJoiner.clear();
        // GameDB.LeagueList.clear();
        // GameDB.AppSetting.clear();
        GameDB.PreviousLeague.clear();
        // GameDB.Profile.clear();
        // db.Data.clear();
        
        // Dexie.delete('TelegramDB');
        // Dexie.delete('MembersgramDB');



        history.push("/");
        window.location.reload();
    }

    return (
        <>
            {LoadingState ?
                <Loading /> :
                <div className='LogOutContainer'>
                    <div className='TransferHistoryHeader'>
                        <Link to="/Profile">
                            <img src={Back} className='TransferHistoryHeaderImg' />
                        </Link>
                        <div className='TransferHistoryHeaderText'>Log out</div>
                    </div>
                    <div className='LogOutBody'>

                        {/* <a href="mailto:membersgramapp@gmail.com?body=Hello," */}
                        <a className='LogOutFlexItem' href="mailto:membersgramapp@gmail.com?body=Hello,">
                            <div><EmailOutlinedIcon className='LogOutIcons' /></div>
                            <div className='LogOutInnerItem'>
                                <div className='LogOutTextInner1'>Send email to support</div>
                                <div className='LogOutTextInner2'>Email us if you are dissatisfied</div>
                            </div>
                        </a>
                        <a href="https://membersgram.com/google/google/" className='LogOutFlexItem'>
                            <div><QuizOutlinedIcon className='LogOutIcons' /></div>
                            <div className='LogOutInnerItem'>
                                <div className='LogOutTextInner1'>Frequently Asked Questions</div>
                                <div className='LogOutTextInner2'>If you have a problem, here is the answer</div>
                            </div>
                        </a>
                        <hr style={{ "border": "1px solid #E7E7E7", "width": "100%" }} />
                        {
                            CanDelete ?

                                <Link className='LogOutFlexItem' to={`/DeleteAccount`} style={{ "height": "56px" }}>
                                    <div><NoAccountsOutlinedIcon className='LogOutIconsRed' /></div>
                                    <div className='LogOutInnerItem'>
                                        <div className='LogOutTextInner1 LogOutIconsRed'>Delete account</div>
                                    </div>
                                </Link>
                                :
                                <div className='LogOutFlexItemDisable' style={{ "height": "56px" }}>
                                    <div><NoAccountsOutlinedIcon className='LogOutIconsGrey' /></div>
                                    <div className='LogOutInnerItemDisable'>
                                        <div className='LogOutTextInner1 LogOutIconsGrey'>Delete account</div>
                                        {
                                            NegativeCoin ?
                                             <div className='LogOutTextInner1 LogOutIconsGrey'>You can't delete your account when your coins are negative</div>
                                                : 
                                                <div className='LogOutTextInner1 LogOutIconsGrey'>You can only delete your account once in a month</div>
                                        }


                                    </div>
                                </div>
                        }

                        <div className='LogOutFlexItem' onClick={() => OpenBottomSheet()} style={{ "height": "56px" }}>
                            <div><LogoutOutlinedIcon className='LogOutIconsRed' /></div>
                            <div className='LogOutInnerItem'>
                                <div className='LogOutTextInner1 LogOutIconsRed'>Log out</div>
                            </div>
                        </div>

                        <BottomSheet onDismiss={onDismiss} open={open}>
                            <div className='RegisterBeforeBottomSheet'>
                                <div className='RegisterBeforeBottomSheetHeader'>
                                    {/* <img src={ImageBottomSheet} /> */}
                                    <ReportProblemOutlinedIcon style={{ "width": "24px", "height": "24px", "marginRight": "1px" }} />
                                    <div className='RegisterBeforeBottomSheetHeaderText'>
                                        Log out</div>
                                </div>
                                <div className='RegisterBeforeBottomSheetBody'>Are you sure to log out of the user account?</div>
                                <div className='RegisterBeforeBottomSheetFooter'>
                                    <button className='BottomSheetCancelButton' onClick={() => setOpen(false)}>Cancel</button>
                                    <button onClick={() => LogOutUser()} className='BottomSheetLoginButton'>Log out</button>
                                </div>
                            </div>
                        </BottomSheet>
                    </div>
                </div>
            }


        </>

    )
}

export default LogOut