import React from 'react'
import Add from './../Public/Image/Join/Add.svg'
import TelegramAdd from './../Public/Image/Join/telegramadd.svg'
import rafiki from './../Public/Image/Join/rafiki.svg'
import './JoinAddAccount.css'
import { Link } from 'react-router-dom';
import Animation from './../Public/Image/Join/1.json'
import Lottie from "lottie-react";
import xpplus from './../Public/Image/Join/xpplus.svg'
import coinplus from './../Public/Image/Join/coinplus.svg'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
const AddAccount = () => {


        const joinCoinRewards = useSelector((state) => state.Game.joinCoinRewards)
        const joinXpRewards = useSelector((state) => state.Game.joinXpRewards)

           const AddXpFirstregister = useSelector((state) => state.Game.AddXpFirstregister)
            const AddCoinFirstregister = useSelector((state) => state.Game.AddCoinFirstregister)
            useEffect(() => {
              console.log(joinCoinRewards)
            }, [joinCoinRewards])
            
    return (
        <div className='AddAccountContainer'>
            <div>
                <Lottie animationData={Animation} loop={true} style={{ "width": "125px", "height": "133px" }} />
                {/* <img alt='rafiki'  src={rafiki} /> */}
            </div>
            <div className='AddAccountTitle'>
                FREE Coins by Join
            </div>
            <div className='AddAccountDes'>
                <ul className='AddAccountDesTextItem1Ul'>
                    <li className='AddAccountDesTextItem1'>
                    Add Telegram account and get &nbsp;
                         { AddCoinFirstregister? <>{AddCoinFirstregister} <img src={coinplus} className='CoinInAddAcount' /></> :null  }  
                         {AddCoinFirstregister > 0  && AddXpFirstregister > 0 ? `and` :null }
                         {AddXpFirstregister? <>  {AddXpFirstregister}  <img src={xpplus} className='XpInAddAcount' /></> :null}
                        
                    </li>
                    <li className='AddAccountDesTextItem1'>
                        {/* Earn {joinCoinRewards}   <img src={coinplus} className='CoinInAddAcount' />
                        and {joinXpRewards}  <img src={xpplus} className='XpInAddAcount' />     per Join */}
                          { joinCoinRewards? <> Earn {joinCoinRewards} <img src={coinplus} className='CoinInAddAcount' /></> :null  }  
                         {joinXpRewards > 0  && joinXpRewards > 0 ? `and` :null }
                         {joinXpRewards? <>  {joinXpRewards}  <img src={xpplus} className='XpInAddAcount' /></> :null}
                        {joinCoinRewards || joinXpRewards?<> per Join</> : null}

                    </li>
                    <li className='AddAccountDesTextItem1'>
                        {/* Earn up to 1,000  <img src={coinplus} className='CoinInAddAcount' />
                        and 1,000   <img src={xpplus} className='XpInAddAcount' />    per Telegram account */}
                          { joinCoinRewards? <> Earn up to {joinCoinRewards * 500} <img src={coinplus} className='CoinInAddAcount' /></> :null  }  
                         {joinXpRewards > 0  && joinXpRewards > 0 ? `and` :null }
                         {joinXpRewards? <>  {joinXpRewards *500}  <img src={xpplus} className='XpInAddAcount' />  </> :null}
                        {joinCoinRewards || joinXpRewards?<> per Telegram account</> : null}
                    </li>
                    <li className='AddAccountDesTextItem1'>
                    Start  <strong >Game</strong> with <strong >Join</strong> and get prize
                    </li>
                </ul>
            </div>
            {/* <div className='AddAccountDes'>
                        <div className='AddAccountDesSteps'>
                            <div className='AddAccountDesStepsItem'>1</div>
                            <div className='AddAccountDesStepsItemLine'></div>
                            <div className='AddAccountDesStepsItem'>2</div>
                        </div>
                        <div className='AddAccountDesText'>
                            <div className='AddAccountDesTextItem1'>
                                Add Telegram account
                            </div>
                            <div className='AddAccountDesTextItem2'>
                                After that, join channels/groups and coins will be automatically added to your main account 
                            </div>
                        </div>
                    </div>
                    <div className='AddAccountWarning'>
                        ⚠️ <strong >Warning</strong>: Please avoid adding your primary Telegram account and use Virtual number
                    </div> */}

            <div className='AddAccountButtonContainer'>
                <Link to="/LoginTelegram" className='AddAccountButton' >
                    <img alt='add' src={TelegramAdd} className='AddAccountButtonImg' />   Add Telegram account
                </Link>
            </div>

        </div>
    )
}

export default AddAccount