import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import { useSelector, useDispatch } from 'react-redux'

import SuccessPayment from './../Public/Image/Home/SuccessPayment.svg'
import {setBottomSheetsuccessfulPayment} from './../app/Home'
import { useLocation } from 'react-router-dom';
import xpplus from './../Public/Image/Join/xpplus.svg'

const SuccessfulPayment = () => {
    const dispatch = useDispatch()
    // const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)
    const BottomSheetsuccessfulPayment = useSelector((state) => state.Home.BottomSheetsuccessfulPayment)
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const Count = searchParams.get('count');
    const UserName = searchParams.get('username');
    const PaymentReturn = searchParams.get('payment');
    const PaymentReturnView = searchParams.get('paymentview');
    const Point = searchParams.get('point');

    function onDismiss() {
        setTimeout(() => {
        dispatch(setBottomSheetsuccessfulPayment(false))
    }, 300);
    }

// console.log(BottomSheetsuccessfulPayment)
    return (
        <BottomSheet onDismiss={onDismiss} open={BottomSheetsuccessfulPayment}>
         {/* <BottomSheet onDismiss={onDismiss} open={true}>  */}
            <div className='RegisterBeforeBottomSheet'>

                <div className='ShowPriceAndChannelBottomSheetBody'>
                    <div className='successfulPaymentItem'>
                       
                        <div className='UnsuccessfulPaymentValue'>
                            <img src={SuccessPayment} className='UnsuccessfulPaymentImg'/> 
                            successful payment</div>
                            <div className='SuccessfulPaymentText'>
                            {Count} {PaymentReturn === 'true' ?'members':PaymentReturnView==='true'?'View':null} are sending to <span style={{"font-weight":"bold"}}>{UserName}</span>
                            </div>
                           {Point? <div style={{"marginTop":"4px","marginBottom":"4px"}}>+</div>:null}
                            <div>
                           {Point?<div className='pointInBottomSheet'>{Point} <img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px","marginLeft":"4px" }} /></div>: null} 
                            </div>
                    </div>
                   
                </div>
                <div className='RegisterBeforeBottomSheetFooter'>
                    <button  className='BottomSheetShowPriceAndChannelButton' onClick={()=>onDismiss()}>Got it</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default SuccessfulPayment