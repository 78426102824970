import React from 'react'
import './Header.css'
import Membersgram from './../Public/Image/Home/Membersgram.svg'
// import User from './../Public/Image/Home/User.svg'
import Coin from './../Public/Image/Home/Coin.svg'
import { useSelector, useDispatch } from 'react-redux'
// import { GetProfile } from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { setUserCoin } from './../app/Home'
import { useEffect } from 'react'
import { GetCookie } from '../Action/GetCookie'
import { RequestToTelegramDb } from './../Coin/RequestToTelegramDb'
import { setTelegramAccount } from './../app/JoinState'
import { TelegramDB } from './../DataBase/TelegramDB'
import { SetPicture } from './../app/Slice'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { useState } from 'react'
import HeadImg from './../../src/Public/Image/Game/Group 26086606.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import xpplus from './../Public/Image/Join/xpplus.svg'
import { GameDB } from '../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';

const Header = () => {
  const dispatch = useDispatch()
  const Token = GetCookie('Token');
  const CountJoining = useSelector((state) => state.JoinState.CountJoining)
  const GetRequestToTelegramDb = async () => {
    const res = await RequestToTelegramDb()

    dispatch(setTelegramAccount(res))
  }
  GetRequestToTelegramDb()
  const history = useHistory();
  const [xpCount, setxpCount] = useState('')
  const [xpLevel, setxpLevel] = useState('')
  const [xpLimit, setxpLimit] = useState('')
  const [PointDialogState, setPointDialogState] = useState('')
  const GetProfileFunction = async () => {
    // let res = await GetProfile(Token)
    const MakeApiClass = new AllApi();


    let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()

    if (TelegramAccountUpdatelimit.length > 0) {
      let arr = []
      TelegramAccountUpdatelimit.map(item => {
        arr.push(item.BotSession)
      })
      let restransfer = await MakeApiClass.accountTransfer(arr)
      arr = []
    }
    let AppSetting = await GameDB.AppSetting.toArray()
    // console.log(AppSetting)
    let res = await MakeApiClass.GetProfile(Token)
    // console.log(res)
    // console.log(AppSettingRes)

    if (res.code == 200) {
      dispatch(SetPicture(res.data.user.picture))
      setxpCount(res.data.user.xpCount)
      setxpLevel(res.data.user.xpLevel)
      setxpLimit(res.data.user.xpLimit)
      setPointDialogState(AppSetting[0].AppSetting.PointDialog)
      dispatch(setUserCoin(res.data.user.coins))
    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

    } else if (res.code == 999) {
      console.log('error network') 
      handleClick({ vertical: 'bottom', horizontal: 'center' })

    }
  }
   const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };


  useEffect(() => {
    GetProfileFunction()
  }, [])
  const UserCoin = useSelector((state) => state.Home.UserCoin)
  // console.log(xpCount)
  // console.log(xpLevel)
  // console.log(xpLimit)

  function abbreviate_number(num, fixed) {
    //   console.log(num)
    if (num === null || num == undefined || num == false) { return null; } // terminate early
    if (num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = (num).toPrecision(2).split("e"), // get power
      k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
      c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
      d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
      e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

    return e;
  }

  const OpenBottomSheetShowXpDetails = () => {
    setBottomSheetState(true)
  }
  const [BottomSheetState, setBottomSheetState] = useState(false)
  function onDismiss() {
    setTimeout(() => {
      setBottomSheetState(false)
    }, 300);

  }



  return (
    <div className='HeaderContainer'>

      <div className='HeaderLogo' onClick={() => OpenBottomSheetShowXpDetails()}>
        {/* <img src={Membersgram} className='HeaderLogoImg' /> */}
        <div className='HeaderLogoPtLevel' >

          <div className='HeaderProgressBarImgContainer'>
            <div className='HeaderProgressBarAbsoluteNumber' >
              {xpLevel}

            </div>
            <img src={HeadImg} className='HeaderProgressBarImg' />
          </div>

        </div>
        <div className='HeaderLogoPt'>
          <div>

            <span style={{ 'fontWeight': '700' }}>
              {abbreviate_number(xpLimit - xpCount)}
            </span> Pts


          </div>
          <div>
            To next lvl
          </div>
        </div>
      </div>


      <Link to="/Coin?Status=Join" className='HeaderCoin'>
        {/* <div className='HeaderCoin'> */}
        <AddCircleRoundedIcon className='AddCoinIcon' />
        <div>
          {UserCoin.toLocaleString()}
        </div>
        <div className='HeaderCoinImgContainer'>

          {
            CountJoining > 0 ?
              <>
                <div className='CoinHeaderImageLoader'></div>
                <img src={Coin} className='HeaderCoinImg1' />
              </>

              :
              <img src={Coin} className='HeaderCoinImg1' />

          }

        </div>
        {/* </div> */}
      </Link >



      <BottomSheet onDismiss={onDismiss} open={BottomSheetState}>
        <div className='RegisterBeforeBottomSheet'>
          <div className='RegisterBeforeBottomSheetHeader'>

            How can we get Points (<img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px" }} />) ?
          </div>
          <ul className='BottomSheetUlXpUl'>
            {
              PointDialogState ?
                PointDialogState.map((item, i) =>
                  <li key={i} style={{ "marginTop": "8px" }}>{item}</li>
                )
                : null
            }

          </ul>

          <div className='RegisterBeforeBottomSheetFooter'>
            <button className='BottomSheetCancelButton' style={{ "width": "100%" }} onClick={() => setBottomSheetState(false)}>Got it</button>
          </div>
        </div>
      </BottomSheet>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={opens}
        onClose={handleClose}
        message="Something went wrong"
        key={vertical + horizontal}
        autoHideDuration={3000}
      />
    </div>
  )
}

export default Header