import React from 'react'
import './ViewPackItem.css'
import ViewIcon from './../Public/Image/Home/ViewIcon.svg'
// import { GetView } from './../Api/GetView'
// import { GetCookie } from '../Action/GetCookie'
import { useEffect } from 'react'
import { setViewPackageData } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { setLoading } from './../app/Home'
import { AllApi } from './../Api/AllApi'
import { setUserFromApi } from './../app/Home'
import { setIsIranian } from './../app/Home'
import { SetEmail } from './../app/Slice'
import { GetCookie } from './../Action/GetCookie'
import { useState } from 'react'
import xpplus from './../Public/Image/Join/xpplus.svg'

const ViewPackItem = () => {
    const Token = GetCookie('Token')

    const ViewPackageData = useSelector((state) => state.Home.ViewPackageData)
    const PostViewFilter = useSelector((state) => state.Home.PostViewFilter)
    const dispatch = useDispatch()
    const history = useHistory();
    const [UserCoin, setUserCoin] = useState(0)
    // const GetViewFunc = async () => {
    //     dispatch(setLoading(true))
    //     const MakeApiClass = new AllApi();
    //     let res = await MakeApiClass.GetView(false)

    //     if (res.code == 200) {
    //         dispatch(setViewPackageData(res.data.data))
    //         dispatch(setLoading(false))

    //     } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
    //         let name = "Token"
    //         let name1 = "active"
    //         document.cookie = name + '=; Max-Age=-99999999;';
    //         document.cookie = name1 + '=; Max-Age=-99999999;';
    //         history.push("/");

    //     } else {
    //         dispatch(setLoading(true))
    //     }

    // }
    // useEffect(() => {
    //     // console.log(ViewPackageData)

    //     if (ViewPackageData.length === 0) {
    //         // GetViewFunc()
    //     }

    // }, [])
    const CheckViewPackageItem = async (price, count, sku, _id) => {
        // console.log(price, count)
        let coins = await GetProfile()
        // console.log(coins)
        if (coins >= price) {
            history.push(`/GetUsernameView?price=${price}&count=${count}&ByCoin=false&PostViewFilter=${PostViewFilter}&sku=${sku}&_id=${_id}`);
        }else{

        }


    }


    // useEffect(() => {

    //     GetProfile()

    // }, [])
    const GetProfile = async () => {
        const MakeApiClass = new AllApi();
        let ProfileRes = await MakeApiClass.GetProfile(Token)
        let coins
        if (ProfileRes.code == 200) {
            // console.log(ProfileRes.data.user.coins)
            // setUserCoin(ProfileRes.data.user.coins)
            coins = ProfileRes.data.user.coins
            // dispatch(setUserFromApi(ProfileRes.data.user))
            if (ProfileRes.data.user) {
                // let IsIraNianForApi=false
                dispatch(SetEmail(ProfileRes.data.user.email))

            }
        }
        return coins
    }
    // console.log(ViewPackageData)
    return (
        <>
            {
                ViewPackageData.length > 0 ?
                    ViewPackageData.map((ViewItem, i) =>
                        ViewItem.count_post == PostViewFilter ?
                            ViewItem.purchase.map((item, j) =>
                                <div className='ViewPackageItem' key={j} onClick={() => CheckViewPackageItem(item.price, item.view_count, item.sku, item._id)}>
                                    <div className='ViewItem'>
                                        <div>
                                            <img src={ViewIcon} />
                                        </div>
                                        <div className='ViewItemDigit'>
                                            <div className='ViewItemDigitNewPrice'>
                                                {item.view_count.toLocaleString()}
                                            {item.xpCount ?<span className='XpCountInlist'>+ {item.xpCount} <img src={xpplus} className='XpCountInlistIcon' /> </span>: null}  

                                            </div>

                                        </div>
                                    </div>
                                    <div className='ViewItem'>
                                        {item.discountString ? <div className='ViewItemOffDigit'>
                                            {item.discountString}
                                        </div>
                                            : null
                                        }

                                        <div >
                                            <div className='ViewItemOrder'>
                                                <button className='ViewItemOrderButton'>$ {item.price} </button>
                                            </div>
                                            {item.discountDecimal ?
                                                <div className='ViewItemOrderOldPrice'>
                                                    $  {parseFloat(item.price / (1 - item.discountDecimal / 100)).toFixed(2)}

                                                    {/* $  {parseFloat((100 * item.price) / item.discountDecimal).toFixed(2)} */}
                                                </div>
                                                : null
                                            }

                                        </div>

                                    </div>
                                </div>
                            )

                            : null
                    )
                    : null
            }
        </>


    )
}

export default ViewPackItem