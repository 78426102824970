import React from 'react'
import { AllApi } from '../Api/AllApi'
import { GameDB } from './../DataBase/GameDB'
import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress, SetTopJoinerData,
    SetSnackBarEndLeagueState, SetTopJoinerTime, SetIsBetweenLeaguge, SetProfileData
} from './../app/Game'
export class OnlineRequestTopJoinerGame extends React.Component {
    constructor() {
        super();
    }
    async GetAllListJoinsfunc() {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.GetAllListJoins()
        // console.log(Result)

        if (Result.code == 200) {
            Data = Result

        } else if (Result.code == 301) {
            Data = 'Name'

        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
    async OnlineRequestTopJoiner(dispatch) {


        let TopJoinerRes = await this.GetAllListJoinsfunc()
        // console.log(TopJoinerRes)
        if (TopJoinerRes !== undefined) {
            if (TopJoinerRes != 'Network' || TopJoinerRes !== undefined || TopJoinerRes !== "undefined") {
                let LevelUser = false
                // console.log(TopJoinerRes)
                LevelUser = TopJoinerRes.data.data.userInformation.leagueLevel
                // console.log(TopJoinerRes.data)
                dispatch(SetTopJoinerData(TopJoinerRes.data))
                dispatch(SetJoinCountRedux(TopJoinerRes.data.user.joinCount))
                // let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)

                let CashTime1 = TopJoinerRes.data.data.cacheTime

                let NowInNow = new Date().getTime();
                let LastTime = CashTime1 * 1000 + NowInNow;

                GameDB.TopJoiner.toArray(function (TopJoinerItem) {
                    if (TopJoinerItem.length > 0) {
                        GameDB.TopJoiner.toCollection().modify(App => {
                            App.LastTime = LastTime;
                            // App.LastUpdate = NowInNow
                            App.TopJoiner = TopJoinerRes.data.data.topUsers
                            App.userInformation = TopJoinerRes.data.data.userInformation
                        });
                    } else {
                        GameDB.TopJoiner.add({ TopJoiner: TopJoinerRes.data.data.topUsers, userInformation: TopJoinerRes.data.data.userInformation, LastTime, LastUpdate: NowInNow });
                    }

                });
            } else {

                // console.log('TopJoinerRes')
                // handleClick({ vertical: 'bottom', horizontal: 'center' })
                // OfflineRequestTopJoiner()
                if (TopJoinerRes != undefined) {
                    return TopJoinerRes
                }



            }
        }

        return TopJoinerRes
    }


}
