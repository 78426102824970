import React from 'react'
import { AllApi } from '../Api/AllApi'
import { GameDB } from './../DataBase/GameDB'
import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,SetTopJoinerData,SetPrevLeagueData,
    SetSnackBarEndLeagueState, SetTopJoinerTime, SetIsBetweenLeaguge,SetProfileData
} from './../app/Game'
export class OnlineRequestPreviousLeague extends React.Component {
    constructor() {
        super();

    }
    async GetPreviousLeaguefunc() {
        let Data;
        const MakeApiClass = new AllApi();
        let Result = await MakeApiClass.PreviousLeague(1)
        // console.log(Result)

        if (Result.code == 200) {
            Data = Result

        } else if (Result.code == 301) {
            Data = 'Name'

        } else if (Result.code == 999) {
            Data = 'Network'
        }
        return Data
    }
    async OnlineRequestPreviousLeague(dispatch) {


        let PreviousLeagueRes = await this.GetPreviousLeaguefunc()
        // console.log(PreviousLeagueRes)
        if (PreviousLeagueRes != 'Network') {
            let LevelUser = false
            // LevelUser = TopJoinerRes.data.data.userInformation.leagueLevel

            dispatch(SetPrevLeagueData(PreviousLeagueRes.data.data))
            // let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)
// console.log(PreviousLeagueRes)
            // let CashTime1 = PreviousLeagueRes.data.data.cashTime

            let NowInNow = new Date().getTime();
            // let LastTime = CashTime1 * 1000 + NowInNow;

            GameDB.PreviousLeague.toArray(function (PreviousLeagueItem) {
                if (PreviousLeagueItem.length > 0) {
                    GameDB.PreviousLeague.toCollection().modify(App => {
                        // App.LastTime = LastTime;
                        // App.LastUpdate = NowInNow
                        App.PreviousLeague = PreviousLeagueRes.data.data
                        // App.userInformation = PreviousLeagueRes.data.data.userInformation
                    });
                } else {
                    GameDB.PreviousLeague.add({ PreviousLeague: PreviousLeagueRes.data.data });
                }

            });
        } else {

            // console.log('OfflineRequestPreviousLeague')
            // handleClick({ vertical: 'bottom', horizontal: 'center' })
            // OfflineRequestTopJoiner()
            return PreviousLeagueRes


        }
        return PreviousLeagueRes
    }


}
